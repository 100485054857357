import downloadIcon from 'assets/img/download-icon.svg';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { i18nPath } from 'config/i18nNs';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import {
  parseMarketsListToOptions,
  translateLangName
} from 'utils/countriesAndLangs';
import { getPathInfo } from 'utils/versionUtils';
import styles from './speakUp.module.scss';

const SpeakUp = () => {
  const { lang } = getPathInfo();

  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languagesList, setLanguagesList] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const { t } = useTranslation(['dictionary', 'speakUp']);

  const marketsList =
    t(`${i18nPath.speakUp}`, { countries: [] }).countries || [];

  const onMarketUpdate = (item: {
    label: string;
    value: {
      code: string;
      flag: string;
      languagesAvailable: { languageCode: string; file: string }[];
    };
  }) => {
    const { languagesAvailable } = item?.value;
    const parsedLanguagesList = languagesAvailable.map(
      (language: { file: string; languageCode: string }) => {
        const langName = translateLangName(language?.languageCode, lang);
        return { label: langName, value: language?.languageCode };
      }
    );

    setSelectedMarket(item);
    setLanguagesList(parsedLanguagesList);
    setSelectedLanguage(null);
  };

  const onLangUpdate = (item: { value: string; label: string }) => {
    const { value: code } = item;
    const { code: currentMarket } = selectedMarket?.value;
    const marketInfo = marketsList.find((item) => item.code === currentMarket);
    const currentMarketLangs = marketInfo?.languagesAvailable;
    const languageInfo = marketInfo?.languagesAvailable.find(
      (lang: { languageCode: string; flag: string }) =>
        lang.languageCode === code
    );
    const { file: pdfUrl, languageCode } = languageInfo;
    const langName = translateLangName(languageCode, lang);

    setPdfUrl(pdfUrl);
    setSelectedLanguage({ label: langName, value: languageCode });
  };

  const onDownloadPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      <section className={styles.root}>
        <h3 className={styles.title}>
          {t(`${i18nPath.dictionary}.downloadManual`)}
        </h3>
      </section>
      <Row>
        <Col sm={12} md={6} className={styles.column}>
          <div className={styles.topMargin}>
            <label className={styles.label} htmlFor="select-country">
              {t(`${i18nPath.dictionary}.selectYourCountry`)}
            </label>
            <Dropdown
              options={parseMarketsListToOptions(marketsList, lang)}
              value={selectedMarket}
              onChange={onMarketUpdate}
              placeholder={t(`${i18nPath.dictionary}.selectYourCountry`)}
              displayFlags={true}
            ></Dropdown>
          </div>
        </Col>
      </Row>
      <Row className={styles.downloadRow}>
        <Col sm={12} md={6}>
          <Row>
            <Col sm={12}>
              <label className={styles.label} htmlFor="select-language">
                {t(`${i18nPath.dictionary}.selectLanguagePlaceholder`)}
              </label>
            </Col>
            <Col sm={6}>
              <Dropdown
                options={languagesList}
                value={selectedLanguage}
                onChange={onLangUpdate}
                placeholder={t(
                  `${i18nPath.dictionary}.selectLanguagePlaceholder`
                )}
                disabled={!selectedMarket}
              />
            </Col>
            <Col sm={6}>
              <Button
                variant="primary"
                fullWidth
                onClick={onDownloadPdf}
                disabled={!pdfUrl}
                icon={downloadIcon}
              >
                <div className={styles.btnContent}>
                  <span className={styles.btnIcon}>
                    <img alt={'download icon'} src={downloadIcon} />
                  </span>
                  {t(
                    `${i18nPath.dictionary}.downloadButton`,
                    'Download Manual'
                  )}
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SpeakUp;
