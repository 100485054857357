import Button from 'components/Button';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Heading1 } from 'typography';
import { projectId } from 'utils/urlQueries';
import { getPathInfo, isExcom } from 'utils/versionUtils';
import styles from './notFound.module.scss';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  const goToHome = () => {
    const home =
      'https://www.carlsberggroup.com/who-we-are/about-the-carlsberg-group/global-presence/';

    window.location.href = home;
  };

  const { polVersion } = getPathInfo();

  return (
    <Row>
      <Col sm={0} md={1} />
      <Col sm={11}>
        <div className={styles.root}>
          <Heading1>
            <Trans
              i18nKey={`${i18nPath.master}.pages.errorNotFoundpage.view.title`}
              components={{
                break: <span className="break" />
              }}
            />
          </Heading1>
          <p className={styles.desc}>
            {t(`${i18nPath.master}.pages.errorNotFoundpage.view.description`)}
          </p>
          <div className={styles.button}>
            {polVersion === 'employees' ? (
              <Link
                to={
                  isExcom(projectId)
                    ? '/employees/home-excom'
                    : '/employees/home'
                }
              >
                <Button size="large">
                  <Icon name="icon-diagonal-up-left" size={16} />
                  {t(
                    `${i18nPath.master}.pages.errorNotFoundpage.view.calltoAction`
                  )}
                </Button>
              </Link>
            ) : (
              <Button size="large" onClick={goToHome}>
                <Icon name="icon-diagonal-up-left" size={16} />
                {t(
                  `${i18nPath.master}.pages.errorNotFoundpage.view.calltoAction`
                )}
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NotFound;
