import { i18nPath } from 'config/i18nNs';
import PageLayout from 'layout/PageLayout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Contact from 'screens/Contact';
import EmployersScreen from 'screens/Employers';
import Homepage from 'screens/Homepage';
import Main from 'screens/Main';
import NotFound from 'screens/NotFound';
import Policy from 'screens/Policy';
import { RootState } from 'store/reducers';
import { getI18nItems } from 'utils/format';

export interface RouterProps {
  locPath: string;
}

const Router: React.FC<RouterProps> = ({ locPath }) => {
  const validProject = useSelector(
    (state: RootState) => state.data.validProject
  );
  const { t } = useTranslation();

  const policyPages = getI18nItems(t, `${i18nPath.policyPages}`);

  return (
    <Switch>
      <Route path="/employees/home/">
        <PageLayout noNav>
          <Homepage excom={false} />
        </PageLayout>
      </Route>
      <Route path="/employees/home-excom/">
        <PageLayout noNav>
          <Homepage excom />
        </PageLayout>
      </Route>
      <Route path="/employees/employer/">
        <PageLayout noNav>
          <EmployersScreen />
        </PageLayout>
      </Route>
      {validProject && (
        <Route exact path={`/${locPath}`}>
          <PageLayout>
            <Main />
          </PageLayout>
        </Route>
      )}
      {validProject && (
        <Route exact path={`/${locPath}/contact`}>
          <PageLayout>
            <Contact />
          </PageLayout>
        </Route>
      )}
      {validProject &&
        policyPages.map((item) => {
          const { alias, hideNavigation, displaySpeakUp } = item;
          return (
            <Route exact key={`route-${alias}`} path={`/${locPath}/${alias}`}>
              <PageLayout noNav={hideNavigation} alias={alias}>
                <Policy id={alias} displaySpeakUp={displaySpeakUp} />
              </PageLayout>
            </Route>
          );
        })}

      <Route path="*">
        <PageLayout noNav listLangs={false}>
          <NotFound />
        </PageLayout>
      </Route>
    </Switch>
  );
};

export default Router;
