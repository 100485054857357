import { getEnvAlias, getEnvAliasCx, host, isProd } from './env';

const umbracoApiKeys = {
  dev: 'environment-1d5b892f-6b2f-4b37-84f8-7b85d5a50647',
  stg: 'environment-04ffc80c-a8e0-4695-ba13-8321f1b143f0',
  prd: 'environment-168d6608-744c-442e-ab20-053cf4c545d6'
};

export const ENVIRONMENT = getEnvAlias(host);
export const ENVIRONMENT_CX = getEnvAliasCx(host);
export const API_BASE_URL = `https://api.carlsbergwebservices.com/ct/${ENVIRONMENT}/ct-serv-legal-pol-be/api`;
export const CONTACT_URL = 'legal-contact';
export const AUTH_URL = isProd
  ? 'https://login.carlsberggroup.com'
  : `https://login.${ENVIRONMENT_CX}.cx-apps.io`;
export const EMPLOYERS_URL = 'corporate-entities-temp-hack';
export const UMBRACO_API = 'corporate-entities-temp-hack';
export const HEADLESS_API_BASE_URL = `https://api.${ENVIRONMENT_CX}.carlsbergwebservices.com/cx-cslight-headless-contentdelivery`;
export const UMBRACO_API_BASE_URL = `https://delivery.enterspeed.com/v1`;
export const UMBRACO_API_KEY = umbracoApiKeys[ENVIRONMENT];
